import React from "react";

const Footer: React.FC = () => {
  return (
    <div className="Footer w-full bg-[#01347c] text-white text-center py-4">
      Pemerintah Kabupaten Indramayu Copyright © 2024
    </div>
  );
};

export default Footer;
